import { SpaMetaUpdate } from "../cb/components/head/spaMetaUpdate"
import { RoomlistRoot } from "../cb/components/roomlist/roomlistRoot"
import { bindAllDismissibleNotices } from "../cb/dismissibleNotice"
import { addGenderToSubjectLinks, parseRoomListSource } from "../cb/roomList"
import { initSearchInput } from "../cb/ui/searchBar/roomlistSearchInput"
import { trackAllClicks } from "../common/pageActionSampling"
import { isFilterInPathActive } from "../common/featureFlagUtil"
import { BaseEntryPoint, exportEntryPoint } from "./base"
import type { IPageContext } from "../cb/interfaces/context"
import type { RoomListSource } from "../cb/roomList"

export interface IRoomListDossier extends IPageContext {
    refreshFrequency: number
    isOfflineFollowed?: boolean
    roomListSource: RoomListSource
    appName?: string
}

export class RoomListPage extends BaseEntryPoint {
    protected context: IRoomListDossier

    protected init(): void {
        super.init()
        initSearchInput("search_icon", true)
        bindAllDismissibleNotices()
        this.bindRoomlistPage()
        trackAllClicks("UIClickHomepage")
        RoomListPage.bindAddGenderToSubjectLinks()
        new SpaMetaUpdate()
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected parseContext(contextObj: Record<string, any>): void {
        super.parseContext(contextObj)
        this.context.refreshFrequency = contextObj["refreshFrequency"]
        this.context.showMobileSiteBannerLink = contextObj["show_mobile_site_banner_link"] === true
        this.context.isOfflineFollowed = contextObj["isOfflineFollowed"]
        this.context.roomListSource = parseRoomListSource(contextObj["roomListSource"])
        this.context.appName = contextObj["appName"]
    }

    private bindRoomlistPage(): void {
        if (document.getElementById("roomlist_root") !== null) {
            if (!isFilterInPathActive()) {
                new RoomlistRoot(this.context)
            } else {
                const roomlistRoot = new RoomlistRoot(this.context)
                const element = document.querySelector("#roomlist_root") as HTMLDivElement
                element.replaceWith(roomlistRoot.element)
            }
        }
    }

    private static bindAddGenderToSubjectLinks(): void {
        addGenderToSubjectLinks(document.body)
    }

    protected setupJoinOverlay(): void { }
}

exportEntryPoint(RoomListPage)
